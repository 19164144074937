import React, { useEffect, useState } from "react";
import "./style.scss";
import defaultIcon from "../../../../assets/images/top_bg1@2x.jpg";
import { Link, useLocation, useHistory } from "react-router-dom";
import TopBanner from "../topBanner";
import isMobile from "ismobilejs";
import Tabs from "antd-mobile/lib/tabs";
import "antd-mobile/lib/tabs/style/index.css";

let timer;

const Layout = (
  props = { english: "", icon: defaultIcon, title: "", menus: [], bgType: "image", bgImage: "", bgVideo: "" }
) => {

  const location = useLocation();
  const history = useHistory();
  const [defaultTop, setDefaultTop] = useState(0);
  const [top, setTop] = useState();

  const getDefaultTop = () => {
    const contentEle = document.getElementsByClassName("TopBanner")[0]
    if (contentEle) {
      setDefaultTop(contentEle.offsetHeight + 60);
      setTop(contentEle.offsetHeight + 60);
    }
  }

  useEffect(() => {
    getDefaultTop()
  }, []);

  useEffect(() => {
    window.onresize = () => {
      getDefaultTop()
    }
    window.onscroll = () => {
      if (isMobile(window.navigator).phone) return;
      const scrollTop = defaultTop - window.scrollY;
      setTop(scrollTop <= 140 ? 140 : scrollTop);
    };
    if (isMobile(window.navigator).phone) {
      window.addEventListener("popstate", function (event) {
        if (!event) return;
        //防抖
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
          try {
            const index = props.menus.findIndex(
              (item) => item.path === event.currentTarget.location.pathname
            );
            if (index >= 0) {
              cusGoToTab(index);
              tabClick(index);
            }
          } catch (err) {
            console.log(err);
          }
        }, 100);
      });
    }
  });

  let cusGoToTab = () => { };

  const renderTabBar = (data) => {
    const {
      tabs = [],
      activeTab = 1,
      onTabClick = () => { },
      goToTab = () => { },
    } = data;
    cusGoToTab = goToTab;
    return (
      <div className={"mobile_tabs"} id={"mobile_tabs_scroll"}>
        {tabs.map((item, index) => (
          <div
            className={`mobile_tabs_item ${activeTab === index ? "active" : ""
              }`}
            key={`mobile_tabs_item_${index}`}
            onClick={() => {
              goToTab(index);
              onTabClick(item);
              tabClick(index);
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
    );
  };

  const tabClick = (index) => {
    const box = document.getElementById("mobile_tabs_scroll");
    const items = box.getElementsByClassName("mobile_tabs_item");
    let left = 0;
    for (let i = 0; i <= index; i++) {
      left += items[i].clientWidth;
    }
    left -=
      (box.clientWidth - items[index].clientWidth) / 2 +
      items[index].clientWidth;
    box.scrollLeft = left;
  };
  return (
    <div className={"layoutComponent"}>
      <TopBanner
        english={props.english}
        icon={props.icon}
        title={props.title}
        bgType={props.bgType}
        bgVideo={props.bgVideo}
        bgImage={props.bgImage}
      />
      {isMobile(window.navigator).phone ? (
        <div className={"layoutComponentMobile"}>
          <Tabs
            prefixCls={"mobileTabs border-1px-bottom"}
            tabs={props.menus.map((item) => ({
              title: item.title,
              key: item.path,
            }))}
            swipeable={false}
            useOnPan={false}
            initialPage={`${location.pathname}`}
            distanceToChangeTab={1}
            renderTabBar={renderTabBar}
            onTabClick={(item) => {
              history.push(item.key);
            }}
          />
          <div className={"mobileTabsContent"}>{props.children}</div>
        </div>
      ) : (
        <div className={"content layoutContent"}>
          <div
            className={"sidebar"}
            style={{
              top: `${top}px`,
            }}
          >
            {props.menus.map(
              (
                item = {
                  title: "",
                  path: "",
                },
                index
              ) => (
                <Link
                  to={item.path}
                  key={`sidebar_${index}`}
                  className={`item ${location.pathname === item.path && "active"
                    }`}
                >
                  {item.title}
                </Link>
              )
            )}
          </div>
          <div className={"occupy"} />
          <div className={"childContent"}>{props.children}</div>
        </div>
      )}
    </div>
  );
};
export default Layout;
