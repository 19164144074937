import React, { useEffect, useState } from "react";
import "./style.scss";
import "swiper/swiper.scss";
import Axios from "../../../../../utils/axios";
import Apis from "../../../../../utils/apis";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../../utils/routes";
import SwiperModal from "../../../../../utils/swiperModal";
import { useMounted } from "../../../../../utils/hook";
import store, { COOPERATION } from "../../../../../utils/store";
import _ from "lodash";
import isMobile from "ismobilejs";

const CompanyProfileTeamBuildingPage = (props) => {
  const history = useHistory();
  const isMounted = useMounted();

  const [cooperationList, setCooperationList] = useState([]);
  useEffect(() => {
    const storeList = store.getArray(COOPERATION);
    setCooperationList(storeList);
    const getData = async () => {
      const res = await Axios({
        url: Apis.cooperation,
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      setCooperationList(data);
      store.setArray(COOPERATION, data);
    };

    (async () => {
      await getData();
    })();
  }, []);

  const renderItemLeft = (item, index) => {
    return (
      <div className="item_left" key={index}>
        <div className="item_left_title" style={{ marginLeft: 22 }}>
          {item.description}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={"item_left_img"} style={{ marginRight: 30 }}>
            <img
              src={`${process.env["ASSETS_URL"]}${item.main_image}`}
              alt=""
            />
          </div>

          <div className={"item_left_img_shadow"} style={{ marginRight: 30 }} />
        </div>
      </div>
    );
  };

  const renderItemRight = (item, index) => {
    return (
      <div className="item_left" key={index}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={"item_left_img"} style={{ marginLeft: 30 }}>
            <img
              src={`${process.env["ASSETS_URL"]}${item.main_image}`}
              alt=""
            />
          </div>

          <div className={"item_left_img_shadow"} style={{ marginLeft: 30 }} />
        </div>
        <div className="item_left_title" style={{ marginRight: 22 }}>
          {item.description}
        </div>
      </div>
    );
  };

  const renderItemMobile = (item, index) => {
    return (
      <div className="item_mobile" key={index}>
        <div className="item_img_mobile">
          <img src={`${process.env["ASSETS_URL"]}${item.main_image}`} alt="" />
        </div>
        <div className={"item_img_shadow_mobile"} />
        <div className="item_title_mobile">{item.description}</div>
      </div>
    );
  };

  return (
    <div className="CompanyProfileTeamBuildingPage">
      <div className="list">
        {isMobile(window.navigator).phone
          ? _.map(cooperationList, (item, index) => {
              return renderItemMobile(item, index);
            })
          : _.map(cooperationList, (item, index) => {
              if (index % 2 === 0) {
                return renderItemLeft(item, index);
              }
              return renderItemRight(item, index);
            })}
      </div>
    </div>
  );
};
export default CompanyProfileTeamBuildingPage;
