import React, { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import "./styleMobile.scss";
import store, {
  HOME_NEWS_LEFT,
  HOME_NEWS_RIGHT,
  NEW_DETAIL,
} from "../../../../utils/store";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import { Routes } from "../../../../utils/routes";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useMounted } from "../../../../utils/hook";
import isMobile from "ismobilejs";

export default function NewsComponet(props) {
  const { hiddenTitle } = props;
  const history = useHistory();
  const [bigItem, setBigItem] = useState();
  const [bigList, setBigList] = useState([]);
  const [smallList, setSmallList] = useState([]);

  const isMounted = useMounted();

  useEffect(() => {
    const getData = async () => {
      const res = await Axios({
        url: Apis.homeNews,
        params: {
          size: 10,
        },
      });
      const { code = 200, data = {} } = res;
      if (code !== 200 || !isMounted) return false;
      let { recommend_news: gallery_news = [], news = [] } = data;
      gallery_news = gallery_news.map((item) => ({
        ...item,
        image: `${process.env["ASSETS_URL"]}${item.image}`,
      }));
      news = news.map((item) => ({
        ...item,
        image: `${process.env["ASSETS_URL"]}${item.image}`,
      }));
      store.setArray(HOME_NEWS_LEFT, gallery_news);
      store.setArray(HOME_NEWS_RIGHT, news);
      const newBigNews = [];
      if (_.size(gallery_news) > 0) {
        newBigNews.push(gallery_news[0]);
      }
      if (_.size(news) > 0) {
        newBigNews.push(news[0]);
      }
      setBigList(newBigNews);
      setSmallList(news);
    };

    (async () => {
      await getData();
    })();
  }, []);

  // useEffect(() => {
  //   const list = store.getArray(HOME_NEWS_LEFT);
  //   const rightList = store.getArray(HOME_NEWS_RIGHT);
  //   if (_.size(list) > 0) {
  //     setBigItem(list[0]);
  //   }
  //   setSmallList(rightList);
  // }, []);

  const detail = (item) => {
    if (!item) return false;
    store.setObject(NEW_DETAIL, item);
    history.push(`${Routes.newsDetail}/${item.id}`);
  };

  const renderBigContent = useMemo(() => {
    return (
      <>
        {
          _.map(bigList, (item, i) => {
            const right = i % 2 !== 0 ? 0 : 24;
            return (
              <div className="nc_big_item" style={{ marginRight: right }} key={i} onClick={() => detail(item)}>
                <div className="nc_big_item_img_bg">
                  <img className="nc_big_item_img" src={item?.image} />
                </div>
                <div className="nc_big_item_cover">
                  <div className="nc_big_item_cover_title">{item?.title}</div>
                  <div className="nc_big_item_cover_sub_title">{item?.publish_at ? moment(item?.publish_at).format("YYYY年MM月DD日") : ""}</div>
                </div>
              </div>
            );
          })
        }
      </>
    );
  }, [bigList]);

  const renderSmallContent = (smallData) => {
    return (
      <div className="nc_small_item" onClick={() => detail(smallData)}>
        <div className="nc_small_item_img_bg">
          <img className="nc_small_item_img" src={smallData?.image} />
        </div>

        <div className="nc_small_item_bottom">
          <div className="title">{smallData?.title}</div>
          <div className="time_bg">
            <div className="time">
              {smallData?.publish_at
                ? moment(smallData?.publish_at).format("YYYY年MM月DD日")
                : ""}
            </div>
            <div className="time_more_img" />
          </div>
        </div>
      </div>
    );
  };

  const renderContainerView = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div className="nc_container_mobile">
          <div className="nc_title_mobile">{"最新动态"}</div>
          {_.map([...bigList, ..._.slice(smallList, 1, 4)], (item, i) => {
            const right = i === 2 ? 0 : 24;
            return (
              <div key={i} style={{ width: '100%' }}>
                <div
                  className="nc_small_item_mobile"
                  onClick={() => detail(item)}
                >
                  <div className="nc_small_item_img_bg_mobile">
                    <img
                      className="nc_small_item_img_mobile"
                      src={item?.image}
                    />
                  </div>

                  <div className="nc_small_item_bottom_mobile">
                    <div className="title_mobile">{item?.title}</div>
                    <div className="time_bg_mobile">
                      <div className="time_mobile">
                        {item?.publish_at
                          ? moment(item?.publish_at).format("YYYY年MM月DD日")
                          : ""}
                      </div>
                      <div className="time_more_img_mobile" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <div className="nc_container">
        <div
          className="nc_title"
        // style={{
        //   visibility: hiddenTitle ? "hidden" : "visible",
        // }}
        >
          {"最新动态"}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            zIndex: 1,
          }}
        >
          {renderBigContent}
          {/* {_.size(smallList) > 0 ? renderSmallContent(smallList[0]) : <></>} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "24px",
          }}
        >
          {_.map(_.slice(smallList, 1, 4), (item, i) => {
            const right = i === 2 ? 0 : 24;
            return (
              <div key={i} style={{ width: '100%', marginRight: right }}>
                {renderSmallContent(item)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return renderContainerView();
}
