import React from "react";
import "./style.scss";
import defaultIcon from "../../../../assets/images/top_bg1@2x.png";
import isMobile from "ismobilejs";

const TopBanner = (props = { english: "", icon: defaultIcon, title: "", bgType: "image", bgImage: "", bgVideo: "" }) => {
  const isPhone = isMobile(window.navigator).phone;

  // const height = React.useMemo(() => {
  //   if (props.bgType === 'video') {
  //     return isPhone ? 220 : 1070;
  //   } else {
  //     return isPhone ? 220 : 600;
  //   }
  // }, [])


  return (
    <div className={"TopBanner"} style={{ height: isPhone ? 395 : 0 }}>
      <div className="contentBox">
        {props.bgType === 'video' ? <div className="videoBox">

          <video
            className="bgVideo"
            loop
            id="new_home_video"
            playsInline
            autoPlay
            muted={true}
            show-center-play-btn="false"
            enable-progress-gesture="false"
            poster={props.bgVideo+"?x-oss-process=video/snapshot,t_1000,f_jpg"}
            src={props.bgVideo}
          />
        </div> : <div className="imageBox">
          <img className="bgImage"
            src={props.bgImage}
            alt=""
          />
          <div className={"bannerContent"}>
            <div className={"content"}>
              <div className={isPhone ? "titleEnglish_mobile" : "titleEnglish"}>
                {props.english}
              </div>
              <div className={isPhone ? "line_mobile" : "line"} />
              <div className={isPhone ? "title_mobile" : "title"}>
                {props.title}
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};
export default TopBanner;
