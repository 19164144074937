import React, { useEffect, useState } from "react";
import "./style.scss";
import PublicWelfareColumn from "../../components/publicWelfareColumn";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import store, { SOCIAL_WELFARE } from "../../../../utils/store";
import banner from "../../../../assets/images/top_bg5@3x.png";
// import banner_2 from "../../../../assets/mobile/top_bg5@3x.png";
import project_img_1 from "../../../../assets/projects/donate_img1@2x.png";
import project_img_2 from "../../../../assets/projects/donate_img2@2x.png";
import project_img_3 from "../../../../assets/projects/donate_img3@2x.png";
import project_img_4 from "../../../../assets/projects/donate_img4@2x.png";
import avatar_01 from "../../../../assets/expert/specialist_img01@2x.png";
import avatar_02 from "../../../../assets/expert/specialist_img02@2x.png";
import avatar_03 from "../../../../assets/expert/specialist_img03@2x.png";
import avatar_04 from "../../../../assets/expert/specialist_img04@2x.png";
import avatar_05 from "../../../../assets/expert/specialist_img05@2x.png";
import avatar_06 from "../../../../assets/expert/specialist_img06@2x.png";
import avatar_07 from "../../../../assets/expert/specialist_img07@2x.png";
import avatar_08 from "../../../../assets/expert/specialist_img08@2x.png";
import avatar_09 from "../../../../assets/expert/specialist_img09@2x.png";
import avatar_10 from "../../../../assets/expert/specialist_img10@2x.png";
// import step_bg from "../../../../assets/images/events_bg@2x.png";
// import TopBanner from "../../components/topBanner";
import { useMounted } from "../../../../utils/hook";
// import isMobile from "ismobilejs";
import CustomTab from "../../components/CustomTab";
import CustomStep from "../../components/CustomStep";
// import AnimatedNumber from "animated-number-react";
import anime from "animejs";
import ScrollAnimation from "react-animate-on-scroll";
import isMobile from "ismobilejs";

// 统计数据
const statistics = [
  { label: "参与捐赠", count: "23000", suffix: "+", unit: "人" },
  { label: "募集图书", count: "80000", suffix: "+", unit: "本" },
  { label: "建设乡村学校图书室", count: "69", unit: "所" },
  { label: "受益乡村学生", count: "45000", suffix: "+", unit: "名" },
];
// 公益项目
const welfareProjects = [
  {
    banner: project_img_1,
    title: "河南省慈善联合总会·一本书公益基金",
    content:
      "2021年3月，一本书公益在河南省慈善联合总会设立一本书公益基金，并由北京家音顺达数据技术有限公司捐赠100万元款物，致力通过互联网技术手段，为河南省内的乡村小学建设公益图书室，一站式解决图书类公益存在的透明度低、捐赠后续无反馈、乡村学校图书管理难等问题，实现捐赠图书线上追踪、图书自助借阅云管理、阅读心得在线分享交流，让城乡孩子跨越时空，共读一本书。",
  },
  {
    banner: project_img_2,
    title: "河南省教育厅·一本书志愿服务队",
    content:
      "2022年，一本书公益正式成为河南省教育厅机关党委志愿者项目，并成立河南省教育厅·一本书志愿服务队，旨在号召更多的学校及学生参与到一本书公益活动中，为更多乡村学校捐赠爱心图书。",
  },
  {
    banner: project_img_3,
    title: "中外阅读学研究专业委员会·一本书公益全国推广中心",
    content:
      "2023年4月，中外阅读学研究专业委员会成立一本书公益全国推广中心，启动全国公益捐助计划，致力为全国范围内的老少边区小学捐建智慧公益图书室，并规划在3年内建成100间乡村小学图书室。同时，成立一本书公益阅读指导专家团，首批由10名国内阅读领域知名专家和教授参与组成，旨在打造青少年阅读示范基地，全面提升青少年阅读能力。",
  },
  {
    banner: project_img_4,
    title: "一本书·益起读公益活动",
    content:
      "“一本书·益起读公益活动”是由一本书公益发起的公益读书活动，致力于通过各类线上、线下的平台和通道，联合社会各界力量，共同发起形式多样的阅读活动，旨在提高城乡学生的阅读兴趣和阅读素养，加强城乡孩子以阅读为主题的沟通、交流，缩小城乡差距，促进教育公平。",
  },
];
// 专家团
const teams = [
  {
    avatar: avatar_01,
    name: "郭英剑",
    positions: [
      "中国人民大学首都发展与战略研究院副院长",
      "国务院外语学科评议组成员",
      "教育部高校英语专业教学指导分委员会委员",
      "中外阅读学研究专业委员会会长",
    ],
  },
  {
    avatar: avatar_02,
    name: "张蕾",
    positions: [
      "全国中文核心期刊《中学语文教学》杂志主编、编审",
      "首都师范大学出版社有限责任公司副总编辑",
      "东北师范大学文学院特聘教授，硕士研究生导师",
      "中外阅读学研究专业委员会副理事长兼秘书长",
    ],
  },
  {
    avatar: avatar_03,
    name: "杨枫",
    positions: [
      "上海交通大学外国语学院教授、博士生导师",
      "《当代外语研究》主编",
      "语言教材研究与评估中心主任",
      "中外阅读学研究专业委员会副会长",
    ],
  },
  {
    avatar: avatar_04,
    name: "李卫东",
    positions: [
      "北京教育科学研究院基础教育教学研究中心副主任",
      "北京市特级教师",
      "教育部基础教育语文教学专业指导委员会委员",
      "中外阅读学研究专委会副会长",
    ],
  },
  {
    avatar: avatar_05,
    name: "张文彦",
    positions: [
      "青岛大学文学与新闻传播学院教授、硕士生导师，副院长",
      "青岛大学全民阅读研究中心主任",
      "国家《全民阅读促进条例》、《全民阅读“十三五”时期发展规划》起草组成员",
    ],
  },
  {
    avatar: avatar_06,
    name: "刘正伟",
    positions: [
      "教育部浙江大学基础教育课程研究中心常务副主任",
      "浙江大学教科书研究中心主任",
      "加州大学伯克利分校访问学者",
      "入选教育部新世纪优秀人才计划",
    ],
  },
  {
    avatar: avatar_07,
    name: "童喜喜",
    positions: [
      "儿童文学作家、教育学者、说写课程创始人",
      "“中国阅读三十人论坛”成员兼秘书长",
      "2008年中国十大作家奥运火炬手",
      "2012年度全国推动读书十大人物",
    ],
  },
  {
    avatar: avatar_08,
    name: "胡洁",
    positions: [
      "浙江大学“百人计划”研究员、博士生导师",
      "“新世纪优秀人才支持计划”",
      "浙江大学“仲英青年学者”入选教育部",
      "教育部国别和区域研究备案中心主任",
    ],
  },
  {
    avatar: avatar_09,
    name: "冀小婷",
    positions: [
      "天津师范大学外国语学院教授、院长",
      "“十四五”中小学幼儿园教师国家级培训计划专家",
      "全国教育硕士专业学位优秀教师",
      "教育部示范性工作坊项目优秀主持人",
    ],
  },
  {
    avatar: avatar_10,
    name: "曹洪彪",
    positions: [
      "北京市朝阳区教育科学研究院语文教科研员",
      "北京市中学语文特级教师",
      "新概念快速作文创始人",
      "中国文章学研究会副会长",
    ],
  },
];

const textVideo = [
  {
    title: "捐书追溯，激发善意",
    des: "一本书公益实现了从图书捐赠、入库、上架到借阅、归还，全程线上可追溯。",
    start: 1,
    end: 5,
  },
  {
    title: "自助借还，高效流通",
    des: "一本书公益实现了图书自助借阅、归还和线上云管理，减轻乡村学校管理压力。",
    start: 9,
    end: 18,
  },
  {
    title: "分享喜悦，连接感动",
    des: "一本书公益让城乡孩子跨越时间和距离，分享彼此在读到同一本书时的感想。",
    start: 20,
    end: 27,
  },
  {
    title: "既“授鱼”，更“授渔”",
    des: "一本书公益不仅关注乡村孩子的阅读环境，更致力让孩子们通过阅读提升综合素养。",
    start: 29,
    end: 38,
  },
];

const SocialWelfarePage = () => {
  const [info, setInfo] = useState({
    title: "",
    des: "",
    opacity: 0,
  });
  const isMounted = useMounted();
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(store.getArray(SOCIAL_WELFARE));
    const getData = async () => {
      const res = await Axios({
        url: Apis.socialWelfare,
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      store.setArray(SOCIAL_WELFARE, data);
      setList(data);
    };

    (async () => {
      await getData();
    })();
  }, []);

  //处理视频文字
  const onTimeUpdate = (e) => {
    // console.log(parseInt(e.target.currentTime), '时间')
    let currentTime = parseInt(e.target.currentTime);
    if (currentTime >= 1 && currentTime < 5) {
      setInfo({
        title: textVideo[0].title,
        des: textVideo[0].des,
        opacity: 1,
      });
      return false;
    }
    if (currentTime >= 9 && currentTime < 18) {
      setInfo({
        title: textVideo[1].title,
        des: textVideo[1].des,
        opacity: 1,
      });
      return false;
    }
    if (currentTime >= 22 && currentTime < 27) {
      setInfo({
        title: textVideo[2].title,
        des: textVideo[2].des,
        opacity: 1,
      });
      return false;
    }
    if (currentTime >= 31 && currentTime < 38) {
      setInfo({
        title: textVideo[3].title,
        des: textVideo[3].des,
        opacity: 1,
      });
      return false;
    }
    setInfo({
      ...info,
      opacity: 0,
    });
  };

  useEffect(() => {
    // 监听元素是否进入可视区域,进入加载动画
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // console.log(entry);
          if (entry.isIntersecting) {
            // 给数字加效果
            entry.target
              .querySelectorAll(".statistics .count")
              .forEach((ele) => {
                const num = ele.getAttribute("data-quantity") || 0;
                anime({
                  targets: ele,
                  innerHTML: [0, Number(num)],
                  round: 1,
                  easing: "linear",
                });
              });
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.3,
      }
    );
    const dom = document.querySelector(".statistics");
    observer.observe(dom);
  }, []);

  return (
    <div className="SocialWelfarePage">
      <div
        className={"topBannerModular"}
        style={{ height: isMobile(window.navigator).phone ? 395 : 0 }}
      >
        <div className={"bgImg"}>
          <div
            className={"_title"}
            style={{
              opacity: info.opacity,
            }}
          >
            {info.title}
          </div>
          <div
            className={"_des"}
            style={{
              opacity: info.opacity,
            }}
          >
            {info.des}
          </div>
          {/* </ScrollAnimation> */}
        </div>
        <div className='bannerBox'>
          <video
            className={"video_bg"}
            style={{ height: isMobile(window.navigator).phone ? 395 : '100%' }}
            loop
            playsInline
            id="myVideo"
            autoPlay
            muted={true}
            onTimeUpdate={(e) => onTimeUpdate(e)}
            show-center-play-btn="false"
            enable-progress-gesture="false"
            poster="https://yidu-res.yunshuguan.cn/official/website/home/home_video.mp4?vframe/jpg/offset/1/w/375/395"
            src="https://yidu-res.yunshuguan.cn/official/website/home/home_video.mp4"
          />
          <div
            className="video_mask"
            style={{ height: isMobile(window.navigator).phone ? 395 : '100%' }}
          />
        </div>

      </div>
      {/* 统计数据 */}
      <div className="statistics">
        <div className="content">
          {statistics.map((ele) => {
            return (
              <div className="item" key={ele.label}>
                <div className="number">
                  <span className="count" data-quantity={ele.count}></span>
                  {ele.suffix && <span className="suffix">{ele.suffix} </span>}
                  <span
                    style={{
                      alignSelf: "flex-end",
                      color: "#333",
                      fontSize: 15,
                      marginBottom: 15,
                      marginLeft: 5,
                    }}
                  >
                    {ele.unit}
                  </span>
                </div>
                {/* <span className="unit">{ele.unit}</span> */}
                <div className="label">{ele.label}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* 项目介绍 */}
      <div>
        {list.map((item, index) => {
          return (
            <PublicWelfareColumn
              key={`PublicWelfareColumn_item_${item.id}`}
              title={item.title}
              subtitle={item.subtitle}
              subtitleUrl={item.subtitle_url}
              info={item.content}
              video={
                item.video.startsWith("http://") ||
                  item.video.startsWith("https://")
                  ? item.video
                  : `${process.env["ASSETS_URL"]}${item.video}`
              }
              bg={
                item.background_image
                  ? `${process.env["ASSETS_URL"]}${item.background_image}`
                  : ""
              }
              bgColor={item.background_color}
              swiper={item.images
                .split(",")
                .map((v) => `${process.env["ASSETS_URL"]}${v}`)}
              news={item.list}
              type={item.type}
            />
          );
        })}
      </div>
      {/* 公益项目 */}
      <div className="projects">
        <div className="layer-title">公益项目</div>
        <div className='adapted_wrap content'>
          <div className="adapted_box">
            {welfareProjects.map((ele) => {
              return (
                <div className="item" key={ele.title}>
                  <div className="img">
                    <img src={ele.banner} alt="" />
                  </div>
                  <div className="info">
                    <div className="title">{ele.title}</div>
                    <div className="desc">{ele.content}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* 专家团 */}
      <div className="expertTeam">
        <div className='expertTeam_adapted_wrap'>
          <div className='expertTeam_adapted_box'>
            <div className="layer-title">
              <div className="title">阅读指导专家团（首批）</div>
              {/* <div className="subTitle">一本书公益</div> */}
            </div>
            <div className="cont">
              {teams.map((ele) => {
                return (
                  <div className="item" key={ele.name}>
                    <div className="left">
                      <img src={ele.avatar} alt="" />
                    </div>
                    <div className="right">
                      <div className="name">{ele.name}</div>
                      <div className="position">
                        {ele.positions.map((item) => {
                          return (
                            <div className="row" key={item}>
                              {item}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* 大事记 */}
      <div className="memorabilia">
        <div className="content">
          <div className="layer-title">大事记</div>
          <CustomStep />
        </div>
      </div>
      {/* 链接 */}
      <div className="linkTab">
        <div className="content">
          <CustomTab />
        </div>
      </div>
    </div>
  );
};
export default SocialWelfarePage;
